const baseurl = "https://apexbanking.live/banking"
const promoBasedUrl = "https://apexbanking.live/apex-azure-promo"



export const API_LOGIN = baseurl + "/account/login";
export const API_LOGOUT = baseurl + "/account/logout";
export const API_USER_DETAILS = baseurl + "/get/user-details";


// Prpmotional banner Urls
export const CAR_LOAN_BANNER_API = promoBasedUrl + "/get/car-loan-promo";
export const HOME_LOAN_BANNER_API = promoBasedUrl + "/get/home-loan-promo";
export const LIFE_INSURANCE_BANNER_API = promoBasedUrl + "/get/insurance-promo";
export const CREDIT_CARD_BANNER_API = promoBasedUrl + "/get/credit-card-promo";


// banking/get/banking-account-summary

// Dasboard Url
export const BANKING_ACCOUNT_SUMMARY_URL = baseurl + "/get/banking-account-summary";

// Credit Card Summary
export const CREDIT_CARD_SUMMARY_URL = baseurl + "/get/credit-card-summary";

// Investment Portfolio
export const INVESTMENT_PORTFOLIO_URL = baseurl + "/get/investment-portfolio";

// Saving Account Summary
export const SAVING_ACCOUNT_SUMMARY_URL = baseurl + "/get/savings-account-summary";

// Payee Details
export const ADD_PAYEES_URL = baseurl + "/post/add-payee";
export const DELETE_PAYEES_URL = baseurl + "/post/delete-payee";
export const PROCCESS_TRANSACTION_URL = baseurl + "/post/process-transaction";
export const GET_PAYEES_DETAILS_URL =  baseurl + "/get/payee-details";

export const GET_USER_PROFILE_DETAILS_URL = baseurl + "/get/user-details";







// export const API_CHANGE_PASSWORD = baseurl +"/dataingester/account/changepassword";

