

// Login Page
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_IS_LOADING = 'LOGIN_IS_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_DETAILS = 'USER_DETAILS';
// export const CHANGE_PASSWORD_IS_LOADING = 'CHANGE_PASSWORD_IS_LOADING';

export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

