import React, { useState, useEffect } from 'react'

const CarsoualPromotion = ({ images, interval }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, interval);
  
      return () => clearInterval(intervalId);
    }, [images.length, interval]);
  
    return (
      <div className="carousel">
        <img  className="hero-img-log"src={images[currentIndex]} alt={`Image ${currentIndex}`} />
      </div>
    );
  };

export default CarsoualPromotion