

import React from "react";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { SimpleSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";
import { ZipkinExporter } from "@opentelemetry/exporter-zipkin";
import { API_URL } from "./constants";
 import { W3CTraceContextPropagator  } from "@opentelemetry/core";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";



const serviceName = "APEX_BANK_FRONTEND";
const hostname = window.location.hostname;
console.log(hostname)

const TraceProvider = ({ children }) => {
  const exporter = new OTLPTraceExporter({
    url: "https://apexbanking.live/v1/traces",
    serviceName: serviceName,
    "host.name": "apex-ohio"
  });

  const provider = new WebTracerProvider({
    resource: new Resource({
      "service.name": serviceName,
      "host.name": "apex-ohio"
    }),
     
     spanProcessor: new SimpleSpanProcessor(exporter),
  });

  provider.addSpanProcessor(new SimpleSpanProcessor(exporter));

  provider.register({
    contextManager: new ZoneContextManager()
  });

  const fetchInstrumentation = new FetchInstrumentation();

  registerInstrumentations({
    instrumentations: [fetchInstrumentation]
  });

   
   provider.register({
    propagator: new W3CTraceContextPropagator(),
  });

  console.log("TraceProvider initialized");

  return <>{children}</>;
};

export default TraceProvider;
