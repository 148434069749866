// import AlertActions from './alert.action';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as ActionTypes from '../Constants/ActionTypes';
import * as ApiUrls from '../Constants/ApiUrls';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { context, propagation, trace, SpanKind } from '@opentelemetry/api';

import { W3CTraceContextPropagator } from '@opentelemetry/core'



const login = (email, password) => {

    return async (dispatch) => {
        let span; 
        try {
            propagation.setGlobalPropagator(new W3CTraceContextPropagator());
            dispatch(loginIsLoading(true));

            
            const tracer = trace.getTracer('api-tracer');
            span = tracer.startSpan('LOGIN');

           
            // if (!trace.context.active()) {
            //     throw new Error('No active context available.');
            // }

           const activeContext = context.active();
           const spanWithContext = trace.setSpan(activeContext, span);
           const headers = {};
           propagation.inject(spanWithContext, headers);
           
            // Make the API call
            const response = await axios.post(`${ApiUrls.API_LOGIN}`, {
                username: email,
                pin: password,
            }, { headers });

            // Handle the response
            if (response.status === 200) {
                
                const passResponse = response.data;
                dispatch(setCurrentUser(jwt_decode(passResponse.access)));
                dispatch(setAuthToken(passResponse));
                localStorage.setItem('authTokens', JSON.stringify(passResponse));
            } else {
                const failResponse = response.data;
                Swal.fire({
                    title: 'Something went wrong',
                    showCancelButton: true,
                    confirmButtonColor: '#000',
                    cancelButtonColor: '#22C9B7',
                    confirmButtonText: 'OK',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Something went wrong',
                showCancelButton: true,
                confirmButtonColor: '#000',
                cancelButtonColor: '#22C9B7',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
            console.error(error);
        } finally {
            // End the API call span
            span.end();

            // Set loading state to false
            dispatch(loginIsLoading(false));
        }
    };
};




const logout = () => {
    return (dispatch) => {
        dispatch(setAuthToken(null))
        dispatch(setCurrentUser(null))
        localStorage.removeItem('authTokens')
    }
}



// const changePassword = (token, credentials) => {
//     return async (dispatch) => {
//         if (!credentials.password || !credentials.newPassword || !credentials.cnewPassword) {
//             dispatch(alert("Error", "Password fields should not be empty."))
//         } else {
//             dispatch(changePasswordIsLoading(true))
//             try
//             {
//                 const response = await fetch(ApiUrls.API_CHANGE_PASSWORD, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         "Authorization": "Bearer " + String(token.access)
//                     },
//                     body: JSON.stringify({
//                         oldPassword: credentials.password,
//                         newPassword: credentials.newPassword,
//                         cnewPassword: credentials.cnewPassword
//                     })
//                 });
//                 if (response.status === 200) {
//                     var passResponse = await response.json();
//                     dispatch(changePasswordIsLoading(false))
//                     dispatch(alert("Success", passResponse.success))

//                 } else {
//                     var failResponse = await response.json();
//                     await dispatch(changePasswordIsLoading(false))
//                     if (failResponse.error === undefined) {
//                         let response = failResponse.messages[0]['message']
//                         dispatch(alert("Error", "Session " + response + ". Please login again."))
//                         dispatch(logout())
//                     }
//                     else {
//                         dispatch(alert("Error", failResponse.error))
//                     }
//                 }
//             } catch (error) {
//                 dispatch(changePasswordIsLoading(false))
//                 dispatch(alert("Error", "Something went wrong."))
//             }
//         }
//     }
// }


const loginIsLoading = (bool) => ({ type: ActionTypes.LOGIN_IS_LOADING, isLoading: bool })

const setCurrentUser = (user) => ({ type: ActionTypes.SET_CURRENT_USER, currentUser: user })

const setAuthToken = (token) => ({ type: ActionTypes.LOGIN, token: token })

// const changePasswordIsLoading = (bool) => ({type: ActionTypes.CHANGE_PASSWORD_IS_LOADING, changePasswordIsLoading: bool})

const authMethods = {
    login,
    logout,
    setAuthToken,
    // changePassword,
}



// export const tranferSuccess = (transferDetails)=>({
//     type:ActionTypes.TRANSFER_SUCCESS,
//     payload:transferDetails
// })



export default authMethods;


export const transferSuccess = (transferDetails) => {
    return {
        type: ActionTypes.TRANSFER_SUCCESS,
        payload: transferDetails,
    };
};