import React from 'react'
import ReactLoading from 'react-loading';


function LoadingScreen(props) {
  return (
    <>
      <div id='overlay'>



        <div className='load-sec'>
          <center>

            <ReactLoading type={'cylon'} color={'#22C9B7'} height={'50%'} width={'50%'} />
          </center>
        </div>




      </div>
    </>
  )
}

export default LoadingScreen