import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import rootReducer from './Reducers/rootReducers';
import configureStore from './Store/configureStore'
import TraceProvider from "./TraceProvider";


const store = configureStore();
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);



root.render(
  <TraceProvider>
    <BrowserRouter>
      <Provider store={store}>
      
        <App />
      
      </Provider>
    </BrowserRouter>
    </TraceProvider>
    ,
  
 
);

reportWebVitals();
